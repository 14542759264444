import React, { useEffect, useState } from 'react'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/hr'
import 'moment/locale/fi'
import 'moment/locale/uk'
import 'moment/locale/hu'
import 'moment/locale/tr'
import 'moment/locale/sl'
import 'moment/locale/sk'
import 'moment/locale/sv'
import 'moment/locale/ru'
import 'moment/locale/pl'
import 'moment/locale/nl'
import 'moment/locale/hr'
import 'moment/locale/bg'
import 'moment/locale/pt'
import 'moment/locale/nn'
import 'moment/locale/cs'
import 'moment/locale/da'
import 'moment/locale/el'
import 'moment/locale/nl-be'

import 'react-dates/initialize'
import { SingleDatePicker, isInclusivelyAfterDay } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import '../../style/datepicker.scss'
import { colorBySeverity, Label } from './InputField'
import styled from 'styled-components'
import CalenderIcon from '../../assets/custom-icons/calendar.svg'

const DatepickerStyled = styled.div`
  position: relative;

  --severity: ${(props) =>
    props.$isInFocus
      ? props.theme.color.anthracite900
      : props.$isInputFilled
      ? props.theme.color.anthracite800
      : props.theme.color.anthracite400};

  // Needs to be an property value. A change in $severity wasn't registered, so the value did not change
  ${(props) => {
    if (props.$severity)
      return `--severity: ${
        props.theme.color[colorBySeverity[props.$severity]]
      }`
  }}
`

const IconWrapper = styled.div`
  svg {
    fill: var(--severity);
  }
`

const languageISOReplace = [
  { curent: 'gr', replaced: 'el' },
  { curent: 'dk', replaced: 'da' },
  { curent: 'se', replaced: 'sv' },
  { curent: 'cz', replaced: 'cs' },
  { curent: 'no', replaced: 'nn' },
  { curent: 'fl', replaced: 'nl-be' },
]

export const Datepicker = ({
  name,
  label,
  language,
  value,
  onChange,
  onBlur,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    languageISOReplace.map((replacedISO) =>
      language === replacedISO.curent
        ? moment.locale(replacedISO.replaced)
        : moment.locale(language)
    )
  }, [language])

  const handleFocus = (focus) => {
    if (focus) {
      onBlur()
    }

    setIsFocused(focus)
  }

  return (
    <DatepickerStyled $isInFocus={isFocused} $isInputFilled={!!value}>
      <Label labelFor={name} $isInputFilled={!!value}>
        {label}
      </Label>
      <div>
        <SingleDatePicker
          date={value}
          placeholder=''
          onDateChange={(date) => onChange(date)}
          focused={isFocused}
          onFocusChange={({ focused }) => handleFocus(focused)}
          isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel={true}
          firstDayOfWeek={1}
          noBorder={true}
          readOnly={true}
          displayFormat='DD.MM.YYYY'
          customInputIcon={
            <IconWrapper>
              <CalenderIcon />
            </IconWrapper>
          }
          inputIconPosition='before'
          renderDayContents={(day) => {
            return <span>{moment(day).format('D')}</span>
          }}
        />
      </div>
    </DatepickerStyled>
  )
}
