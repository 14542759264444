import React, { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container } from './Container'
import PlusIcon from '../assets/custom-icons/close.svg'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Portal } from 'react-portal'

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ModalMain = styled.div`
  visibility: visible;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1100;
  animation: ${fadeInAnimation} 0.25s;
`

const ModalBackground = styled.div`
  background-color: ${(props) => props.theme.color.anthracite800};
  height: 100vh;
  opacity: 0.75;
  width: 100%;
`

const ModalContentOuter = styled.div`
  > * {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(48, 61, 74, 0.25);
    overflow: auto;
    max-height: 100vh;

    ${up('md')} {
      max-height: 90vh;
      width: 90%;
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 5.5px;
  right: 7.5px;
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 10px;

  &:hover {
    svg {
      fill: ${(props) => props.theme.color.manRed500};
    }
  }

  &:focus,
  &:active {
    outline: none;
  }

  ${up('md')} {
    top: 7.5px;
    right: 7.5px;
  }

  svg {
    width: 21px;
    height: 21px;
    fill: ${(props) => props.theme.color.manRed600};
  }
`

export const Modal = ({
  children,
  ModalWrapperComponent = Container,
  showClose = false,
  onClose = () => {},
}) => {
  const modalRef = useRef()

  useEffect(() => {
    const handler = (evt) => {
      if (evt.code === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keyup', handler)

    return () => {
      window.removeEventListener('keyup', handler)
    }
  }, [])

  useEffect(() => {
    const handler = () => {
      disableBodyScroll(modalRef.current)
    }

    window.addEventListener('resize', handler)

    handler()

    const ref = modalRef.current

    return () => {
      window.removeEventListener('resize', handler)
      enableBodyScroll(ref)
    }
  }, [modalRef.current])

  return (
    <Portal>
      <ModalMain>
        <ModalBackground onClick={() => (showClose ? onClose() : () => {})} />
        <ModalContentOuter>
          <ModalWrapperComponent ref={modalRef}>
            {showClose && (
              <CloseButton onClick={onClose}>
                <PlusIcon />
              </CloseButton>
            )}
            {children}
          </ModalWrapperComponent>
        </ModalContentOuter>
      </ModalMain>
    </Portal>
  )
}
