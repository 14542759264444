import React, { useEffect } from 'react'
import { GlobalLayout } from '../layout/GlobalLayout'
import { Helmet } from 'react-helmet'
import { ManStage } from '../components/ManStage'
import { ManPagecontent } from '../components/ManPagecontent'
import styled from 'styled-components'
import { gtmTrackPageView } from '../service/tracking'
import { Headline } from '../components/Headline'
import { ManCopy } from '../components/ManCopy'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { Video } from '../components/Video'
import { ECardForm } from '../components/ECardForm'
import GreetingsVideo from '../assets/videos/2024/MAN_Digital_Xmas_Card_16x9.mp4'
import GreetingsVideoWebM from '../assets/videos/2024/MAN_Digital_Xmas_Card_16x9.webm'

import manStageVideo from '../images/2024/desktop_2024.mp4'
import manStageVideoWebm from '../images/2024/desktop_2024.webm'
import manStageMobileVideo from '../images/2024/mobile_2024.mp4'
import manStageMobileVideoWebm from '../images/2024/mobile_2024.webm'
import manStageTabletVideo from '../images/2024/tablet_2024.mp4'
import manStageTabletVideoWebm from '../images/2024/tablet_2024.webm'
import { PiwikConnector } from '../service/PiwikConnector'
import { OneTrustConnector } from '../service/OneTrustConnector'
import { AdobeTagManager } from '../service/AdobeTagManager'

const StageSpacer = styled.div`
  height: 140px;
`

const HeadlineSpacer = styled.div`
  height: 24px;
`

const CopyToVideoSpacer = styled.div`
  height: 50px;
`

const IndexPage = () => {
  const { i18n, t } = useTranslation()

  useEffect(() => {
    gtmTrackPageView()
  }, [])

  return (
    <GlobalLayout>
      <Helmet
        htmlAttributes={{
          lang: i18n.language,
        }}
        title='MAN | eCard | Home'
      />
      <OneTrustConnector />
      <PiwikConnector />
      <AdobeTagManager />
      <ManStage
        tabletVideo={manStageTabletVideo}
        tabletVideoWebm={manStageTabletVideoWebm}
        mobileVideoLink={manStageMobileVideo}
        mobileVideoWebm={manStageMobileVideoWebm}
        videoLink={manStageVideo}
        videoLinkWebm={manStageVideoWebm}
        headline={
          <div dangerouslySetInnerHTML={{ __html: t('home.stage.headline') }} />
        }
      />

      <ManPagecontent>
        <StageSpacer />

        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-8'>
              <Headline size='xxl' as='h1'>
                <span
                  dangerouslySetInnerHTML={{ __html: t('home.headline') }}
                />
              </Headline>

              <HeadlineSpacer />

              <ManCopy style={{ fontSize: '18px' }}>
                {t('home.introCopy')}
              </ManCopy>

              <CopyToVideoSpacer />
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-10'>
              <Video
                sources={[
                  {
                    src: GreetingsVideoWebM,
                    type: 'video/webm',
                  },
                  {
                    src: GreetingsVideo,
                    type: 'video/mp4',
                  },
                ]}
              />
            </div>

            <CopyToVideoSpacer />
          </div>
        </div>

        <ECardForm />

        <div style={{ height: 100 }} />
      </ManPagecontent>
    </GlobalLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
