import { API_URL } from './baseHttpService'

/**
 *
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} email
 * @param {string[]} recipients
 * @param {string} message
 * @param {string} desiredSendingDate
 * @param {"de"|"en"} language
 * @param {string} reCaptchaToken
 * @return {Promise<any>}
 */
export const createECard = ({
  firstName,
  lastName,
  email,
  recipients,
  message,
  desiredSendingDate,
  language,
  reCaptchaToken,
}) => {
  const headers = new Headers()
  headers.append('X-Api-Key', process.env.GATSBY_API_KEY)
  headers.append('content-type', `application/json`)

  const request = new Request(`${API_URL}/ecard`, {
    method: 'POST',
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      recipients,
      message,
      desiredSendingDate,
      language,
      reCaptchaToken,
    }),
    headers,
  })
  return fetch(request).then((res) => res.json())
}
