import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import {
  InnerWrapper,
  Input,
  InputBorder,
  InputFieldWrapper,
  Label,
} from './InputField'

const TextareaStyled = styled(Input)`
  resize: vertical;
  min-height: 1.9em;
  max-height: 60vh;
  height: 1.9em;
`

export const Textarea = ({
  name,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  severity,
  iconSlot,
  ...props
}) => {
  const textareaRef = useRef()
  const [inFocus, setInFocus] = useState(false)

  const isInputFilled = value.length > 0

  const updateHeight = () => {
    if (!textareaRef.current) return

    const maxHeight = getComputedStyle(textareaRef.current).maxHeight || ''
    const maxHeightValue = Number(maxHeight.replace('px', ''))

    textareaRef.current.style.height = `${
      textareaRef.current.scrollHeight > maxHeightValue
        ? maxHeightValue
        : textareaRef.current.scrollHeight
    }px`
  }

  return (
    <InputFieldWrapper
      $severity={severity}
      $isInputFilled={isInputFilled}
      $isInFocus={inFocus}
    >
      <Label labelFor={name} $isInputFilled={isInputFilled}>
        {label}
      </Label>
      <InnerWrapper>
        <TextareaStyled
          as='textarea'
          value={value}
          name={name}
          ref={textareaRef}
          onChange={(evt) => {
            typeof onChange === 'function' && onChange(evt)
            updateHeight()
          }}
          onFocus={(evt) => {
            typeof onFocus === 'function' && onFocus(evt)
            setInFocus(true)
          }}
          onBlur={(evt) => {
            typeof onBlur === 'function' && onBlur(evt)
            setInFocus(false)
          }}
          {...props}
        />
        {iconSlot && iconSlot}
        <InputBorder $isInFocus={inFocus} />
      </InnerWrapper>
    </InputFieldWrapper>
  )
}
