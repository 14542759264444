import React, { useState } from 'react'
import { FieldWrapper } from './form/FieldWrapper'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FormikInputField } from './form/formik/FormikInputField'
import { FormikTextarea } from './form/formik/FormikTextarea'
import { FormikMultipleInput } from './form/formik/FormikMultipleInput'
import { FormikDatepicker } from './form/formik/FormikDatepicker'
import { LoadingSpinner, LoadingSpinnerOverlay } from './LoadingSpinner'
import { ManButton } from './ManButton'
import styled from 'styled-components'
import { createECard } from '../service/createECard'
import moment from 'moment'
import { minimumTime } from '../utils/minimumTime'
import { FieldMessage } from './form/FieldMessage'
import { Modal } from './Modal'
import { ECardContent } from './ECardContent'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { up } from 'styled-breakpoints'
import { getReCaptchaToken } from '../utils/getReCaptchaToken'
import { structuredTracking } from '../service/tracking'

const FormFooter = styled.footer`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;

  &:first-child {
    margin-right: 10px;
  }

  > * {
    margin-bottom: 25px;
    width: 100%;

    ${up('sm')} {
      width: auto;

      &:first-child {
        margin-right: 25px;
      }
    }
  }
`

const initialValues = {
  firstName: '',
  lastName: '',
  recipients: [],
  email: '',
  desiredSendingDate: null,
  message: '',
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('error.firstName.required'),
  lastName: Yup.string().required('error.lastName.required'),
  recipients: Yup.array().min(1, 'error.emails.invalidFormat').max(250, "You can send this card to a maximum of 250 recipients at the same time"),
  email: Yup.string()
    .email('error.email.invalidFormat')
    .required('error.email.required'),
  message: Yup.string()
    .required('error.message.required')
    .max(10000, 'error.message.maximumLength'),
})

const ModalWrapper = styled.div`
  width: 100%;
  max-width: none;

  ${up('md')} {
    max-width: 95vw;
  }
`

export const ECardForm = () => {
  const { navigate, i18n, t } = useI18next()
  const [genericError, setGenericError] = useState(null)
  const [showPreview, setShowPreview] = useState(false)

  return (
    <div className='container-fluid'>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnMount={true}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setSubmitting(true)
          setGenericError(null)

          structuredTracking({
            eventCategory: 'Website Modules',
            eventAction: 'Button Clicks - Primary',
            eventLabel: t('Weihnachtskarte versenden'),
          })

          const desiredSendingDate = moment
            .utc(values.desiredSendingDate)
            .toISOString()

          const reCaptchaToken = await getReCaptchaToken()

          const eCard = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            recipients: values.recipients,
            message: values.message,
            desiredSendingDate: desiredSendingDate || '',
            language: i18n.language,
            reCaptchaToken,
          }

          let response = null

          try {
            response = await minimumTime(() => createECard(eCard))
          } catch (e) {
            console.error(e)
          }

          formikHelpers.setSubmitting(false)

          if (!response || !response.success) {
            setGenericError('error.form.generic')
          } else {
            const sendingDate = values.desiredSendingDate || moment()

            await navigate('/success', {
              state: {
                sendingDate: sendingDate.format('DD.MM.YYYY'),
              },
              replace: true,
            })
          }
        }}
      >
        {(props) => (
          <Form>
            <div className='row'>
              <div className='col-lg-8'>
                <FieldWrapper>
                  <Field
                    name='recipients'
                    label={`${t('Empfänger E-Mail-Adresse(n)')}*`}
                    component={FormikMultipleInput}
                    helperText={t('error.emails.helperText')}
                  />
                </FieldWrapper>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-8'>
                <FieldWrapper>
                  <Field
                    name='message'
                    label={`${t('Ihre persönlichen Weihnachtsgrüße')}*`}
                    component={FormikTextarea}
                    helperText={t('error.message.helperText')}
                  />
                </FieldWrapper>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-lg-4'>
                <FieldWrapper>
                  <Field
                    name='firstName'
                    label={`${t('Absender Vorname')}*`}
                    component={FormikInputField}
                  />
                </FieldWrapper>
              </div>

              <div className='col-md-6 col-lg-4'>
                <FieldWrapper>
                  <Field
                    name='lastName'
                    label={`${t('Absender Nachname')}*`}
                    component={FormikInputField}
                  />
                </FieldWrapper>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-lg-4'>
                <FieldWrapper>
                  <Field
                    name='email'
                    label={`${t('Absender E-Mail-Adresse')}*`}
                    component={FormikInputField}
                  />
                </FieldWrapper>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-lg-4'>
                <FieldWrapper>
                  <Field
                    name='desiredSendingDate'
                    label={`${t('Versanddatum (optional)')}`}
                    language={i18n.language}
                    component={FormikDatepicker}
                  />
                </FieldWrapper>
              </div>
            </div>

            <FormFooter>
              <ManButton
                width='auto'
                type='submit'
                disabled={!props.isValid}
                onDisabledClick={() => {
                  structuredTracking({
                    eventCategory: 'Website Modules',
                    eventAction: 'Button Clicks - Primary',
                    eventLabel: `${t('Weihnachtskarte versenden')}:disabled`,
                  })
                }}
              >
                {t('Jetzt Weihnachtskarte versenden')}
              </ManButton>

              <ManButton
                width='auto'
                type={props.isValid ? 'button' : 'submit'}
                styleType='tertiary'
                onClick={() => {
                  structuredTracking({
                    eventCategory: 'Website Modules',
                    eventAction: 'Button Clicks - Secondary',
                    eventLabel: t('Vorschau ansehen'),
                  })

                  setShowPreview(true)
                }}
                disabled={!props.isValid}
              >
                {t('Vorschau ansehen')}
              </ManButton>
            </FormFooter>

            {genericError && (
              <>
                <div style={{ height: 25 }} />
                <FieldMessage severity='error' message={t(genericError)} />
              </>
            )}

            {showPreview && (
              <Modal
                showClose={true}
                onClose={() => setShowPreview(false)}
                ModalWrapperComponent={ModalWrapper}
              >
                <ECardContent
                  firstName={props.values.firstName}
                  lastName={props.values.lastName}
                  message={props.values.message}
                />
                <div style={{ height: 50 }} />
              </Modal>
            )}

            {props.isSubmitting && (
              <LoadingSpinnerOverlay position='fixed'>
                <LoadingSpinner />
              </LoadingSpinnerOverlay>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
