import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

const ICON_MAP = {
  panorama: 'e957',
  'overlay-turntable': 'e956',
  camera: 'e955',
  calendar: 'e901',
  cart: 'e902',
  checkmark: 'e903',
  'client-business': 'e904',
  close: 'e905',
  crosshair: 'e906',
  download: 'e907',
  edit: 'e908',
  eye: 'e909',
  'favorite-filled': 'e90a',
  'favorite-outline': 'e90b',
  fax: 'e90c',
  'filter-list': 'e90d',
  filter: 'e90e',
  folder: 'e90f',
  globe: 'e910',
  image: 'e911',
  info: 'e912',
  language: 'e913',
  level: 'e914',
  list: 'e915',
  location: 'e916',
  mail: 'e917',
  maximize: 'e918',
  menue: 'e919',
  microphone: 'e91a',
  minus: 'e91b',
  open: 'e91c',
  phone: 'e91d',
  pin: 'e91e',
  plus: 'e91f',
  print: 'e920',
  'quote-end': 'e921',
  'quote-start': 'e922',
  reset: 'e923',
  result: 'e924',
  rss: 'e925',
  'search-save': 'e926',
  search: 'e927',
  services: 'e928',
  share: 'e929',
  'sign-out': 'e92a',
  stopwatch: 'e92b',
  thumb: 'e92c',
  trash: 'e92d',
  'turn-left': 'e92e',
  usermanagement: 'e92f',
  workshop: 'e930',
  'zoom-in': 'e931',
  'zoom-out': 'e932',
  'doc-document': 'e933',
  'doc-office': 'e934',
  'doc-original': 'e935',
  'doc-pdf': 'e936',
  'doc-web': 'e937',
  'arrow-full': 'e938',
  'arrow-line-thin': 'e939',
  index: 'e954',
  'arrow-line-thin-left': 'e953',
  'arrow-line': 'e93a',
  'arrow-outline': 'e93b',
  'overlay-360': 'e93c',
  'overlay-play': 'e93d',
  'overlay-replay': 'e93e',
  'bus-sideview-1': 'e93f',
  'bus-sideview-2': 'e940',
  bus: 'e941',
  'industry-motor': 'e942',
  mobile24: 'e943',
  trailer: 'e944',
  'truck-sideview-1': 'e945',
  'truck-sideview-2': 'e946',
  truck: 'e947',
  'van-sideview-1': 'e948',
  van: 'e949',
  facebook: 'e94a',
  flickr: 'e94b',
  instagram: 'e94c',
  linkedin: 'e94d',
  twitter: 'e94e',
  whatsapp: 'e94f',
  xing: 'e950',
  'youtube-1': 'e951',
  'youtube-2': 'e952',
  vk: 'e958',
  ok: 'e959',
  placeholder: 'e900',
}

export const Icon = styled.i`
  transform: rotate(${(props) => props.iconRotation || 0}deg);
  display: flex;
  align-items: center;

  &:before {
    font-family: man-icon;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '${(props) => '\\' + ICON_MAP[props.iconName]}';
  }
`

export const ManIcon = (props) => {
  return <Icon {...props} />
}

export const EveryIcon = () => {
  return (
    <>
      {Object.keys(ICON_MAP).map((name) => (
        <>
          <p>{name}</p>
          <ManIcon key={name} iconName={name} />
        </>
      ))}
    </>
  )
}

ManIcon.propTypes = {
  iconName: propTypes.oneOf(Object.keys(ICON_MAP)),
  iconRotation: propTypes.number,
}

ManIcon.propTypes = {
  iconName: propTypes.oneOf(Object.keys(ICON_MAP)),
}
