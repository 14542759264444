import React from 'react'
import { useField } from 'formik'
import { FieldMessage } from '../FieldMessage'
import { Datepicker } from '../Datepicker'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const FormikDatepicker = ({
  helperText = '',
  label,
  language,
  ...props
}) => {
  const { t } = useTranslation()
  const [field, meta, helper] = useField(props.field.name)

  return (
    <>
      <Datepicker
        language={language}
        label={label}
        name={field.name}
        onChange={(value) => helper.setValue(value)}
        onBlur={() => helper.setTouched(true)}
        value={field.value}
        severity={meta.touched && meta.error ? 'error' : null}
      />
      {meta.touched && meta.error && <FieldMessage message={t(meta.error)} />}
      {helperText && <FieldMessage message={helperText} severity='help' />}
    </>
  )
}
