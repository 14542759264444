import React from 'react'
import { useField } from 'formik'
import { Textarea } from '../Textarea'
import { FieldMessage } from '../FieldMessage'
import { useTranslation } from 'react-i18next'

export const FormikTextarea = ({ helperText = '', label = '', ...props }) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props.field.name)

  return (
    <>
      <Textarea
        label={label}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        severity={meta.touched && meta.error ? 'error' : null}
      />
      {meta.touched && meta.error && <FieldMessage message={t(meta.error)} />}
      {helperText && <FieldMessage message={helperText} severity='help' />}
    </>
  )
}
