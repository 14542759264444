import React, { useRef, useState } from 'react'
import { colorBySeverity, InputBorder, Label } from './InputField'
import styled from 'styled-components'
import PlusSvg from '../../assets/custom-icons/plus.svg'
import * as yup from 'yup'
import { up } from 'styled-breakpoints'

const MultipleInputStyled = styled.div`
  position: relative;
  cursor: text;

  --severity: ${(props) => {
    if (props.$severity) {
      return props.theme.color[colorBySeverity[props.$severity]]
    }

    return props.$isInFocus
      ? props.theme.color.anthracite900
      : props.$isInputFilled
      ? props.theme.color.anthracite800
      : props.theme.color.anthracite400
  }};
`

const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InteractiveTagStyled = styled.button`
  --color: rgba(115, 132, 151, 1);

  word-break: break-all;
  display: flex;
  padding: 4px 12px;
  font-size: 14px;
  font-family: ${(props) =>
    props.$isHighlighted
      ? props.theme.fonts.MAN_bold
      : props.theme.fonts.MAN_light};
  color: var(--color);
  border: 1px solid var(--color);
  background: white;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: 0.3s font-family;
  cursor: pointer;
`

const IconWrapper = styled.div`
  margin-left: 12px;
  width: 9px;
  height: 9px;
  position: relative;
  flex-shrink: 0;

  svg {
    transform: rotate(45deg);
    fill: currentColor;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    color: inherit;
  }
`

const InteractiveTag = ({ title, onDelete, isHighlighted }) => {
  return (
    <InteractiveTagStyled onClick={onDelete} $isHighlighted={isHighlighted}>
      {title}
      <IconWrapper>
        <PlusSvg />
      </IconWrapper>
    </InteractiveTagStyled>
  )
}

const InputStyled = styled.input`
  height: 25px;
  display: block;
  border: 0;
  margin-bottom: 10px;
  padding: 0;
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.MAN_light};
  line-height: 25px;
  flex-grow: 1;
  min-width: 75%;

  ${up('md')} {
    min-width: 50%;
  }

  ${up('lg')} {
    min-width: 35%;
  }

  &:focus {
    outline: none;
  }
`

const InputField = React.forwardRef(
  ({ value, setInput, onChange, onEnterPress, onBlur }, ref) => {
    const handleChange = (evt) => {
      const { value } = evt.target

      setInput(onChange(value))
    }

    const onKeyDown = (evt) => {
      const { value } = evt.target
      // Enter Key
      if (evt.keyCode === 13) {
        evt.preventDefault()
        setInput(onEnterPress(value))
      }
    }

    const onInputBlur = (evt) => {
      const { value } = evt.target

      setInput(onBlur(value))
    }

    return (
      <InputStyled
        ref={ref}
        type='text'
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={onInputBlur}
      />
    )
  }
)

let emailSchema = yup.string().email()

export const MultipleInput = ({
  value,
  onChange,
  label,
  name,
  severity,
  onBlur,
  setError,
}) => {
  const [highlightedItems, setHighlightedItems] = useState([])
  const [input, setInput] = useState('')
  const inputRef = useRef(null)

  const isValidItem = (item) => {
    return !isItemDuplicate(item) && isValidEMail(item)
  }

  const isItemDuplicate = (item) => {
    return value.includes(item)
  }

  const isValidEMail = (email) => {
    return emailSchema.isValidSync(email)
  }

  const addItems = (newItems) => {
    let nextState = [...value, ...newItems]

    onChange(nextState)
  }

  const removeItem = (item) => {
    let newItems = value.filter((currentItem) => currentItem !== item)

    onChange(newItems)
  }

  const onInputFieldChange = (value) => {
    const includesSpace = value.includes(' ')
    const includesComma = value.includes(',')
    const includesSemi = value.includes(';')

    const hasSeparator = includesSpace || includesComma || includesSemi

    if (!hasSeparator) return value

    return getAndSetItems(value)
  }

  const onEnterPress = (value) => {
    return getAndSetItems(value)
  }

  const onCustomBlur = (value) => {
    onBlur()

    return getAndSetItems(value)
  }

  const highlightItem = (item) => {
    setHighlightedItems((currentValue) => [...currentValue, item])

    setTimeout(() => {
      setHighlightedItems((currentValue) =>
        currentValue.filter((highlightItem) => highlightItem !== item)
      )
    }, 1500)
  }

  const getAndSetItems = (value) => {
    const validItems = []
    const possibleItems = getItems(value)

    const returnErrors = possibleItems
      .map((item) => {
        if (item.trim().length === 0) return null

        if (isValidItem(item)) {
          validItems.push(item)
          return null
        } else if (isItemDuplicate(item)) {
          highlightItem(item)
          return null
        } else {
          return item
        }
      })
      .filter((val) => !!val)

    addItems(validItems)

    if (returnErrors.length > 0) {
      setError('error.email.invalidFormat')
    } else {
      setError(null)
    }

    return returnErrors.length > 0 ? returnErrors.join('; ') : ''
  }

  return (
    <MultipleInputStyled
      $isInputFilled={input.length > 0 || value.length > 0}
      $isInFocus={input.length > 0}
      onClick={() => inputRef.current.focus()}
      $severity={severity}
    >
      <Label
        labelFor={name}
        $isInputFilled={input.length > 0 || value.length > 0}
      >
        {label}
      </Label>
      <InnerWrapper>
        {value.map((item, index) => (
          <InteractiveTag
            isHighlighted={highlightedItems.find(
              (highlightItem) => highlightItem === item
            )}
            title={item}
            key={index + item}
            onDelete={() => removeItem(item)}
          />
        ))}
        <InputField
          ref={inputRef}
          value={input}
          setInput={setInput}
          onChange={onInputFieldChange}
          onEnterPress={onEnterPress}
          onBlur={onCustomBlur}
        />
      </InnerWrapper>
      <InputBorder />
    </MultipleInputStyled>
  )
}

function getItems(string) {
  return string
    .trim()
    .replace(/^,|,$/g, '')
    .replace(/^;|;$/g, '')
    .replace(/"/g, '')
    .split(/[\s,;]+/)
}
