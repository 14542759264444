import styled from 'styled-components'
import { up } from 'styled-breakpoints'

export const Container = styled.div`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;

  ${up('md')} {
    max-width: 1012px;
  }

  ${up('xl')} {
    max-width: 1200px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (min-width: 1012px) {
    padding-left: 26px;
    padding-right: 26px;
  }
`
