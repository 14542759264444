import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { Icon, ManIcon } from './ManIcon'

const styleBySize = {
  small: {
    padding: '7.5px 8px',
    fontSize: '14px',
    lineHeight: '17px',
  },
  medium: {
    padding: '9px 24px',
    fontSize: '16px',
    lineHeight: '24px',
  },
  large: {
    padding: '11.5px 24px',
    fontSize: '18px',
    lineHeight: '27px',
  },
  'special::stickyButton': {
    padding: '18px 20px',
    fontSize: '16px',
    lineHeight: '24px',
  },
}

const BaseButton = styled.button`
  border: none;
  padding: ${(props) => styleBySize[props.$size].padding};
  margin: 0;
  position: relative;
  color: white;
  font-family: ${(props) => props.theme.fonts.MAN_bold_condensed};
  font-size: ${(props) => styleBySize[props.$size].fontSize};
  line-height: ${(props) => styleBySize[props.$size].lineHeight};
  transition: background-color 0.3s ease;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  background-color: var(--background-color);
  letter-spacing: 0.8px;
  text-align: center;
  width: ${(props) => (props.$width === 'full' ? '100%' : null)};
  display: flex;
  justify-content: center;
  align-items: center;

  //Fixes links
  text-decoration: none;

  ${(props) =>
    !props.$disabled &&
    `
    &:active {
      outline: 2px solid var(--outline-color);
    }

    &:focus,
    &:hover {
      background-color: var(--background-color);
    }
  `}

  ${(props) =>
    props.$disabled &&
    `
    background-color: ${props.theme.color.silver300};
    color: ${props.theme.color.silver700};
    `}
`

const PrimaryButton = styled(BaseButton)`
  --background-color: ${(props) => props.theme.color.manRed};
  --outline-color: rgba(194, 0, 65, 0.2);

  &:focus,
  &:hover {
    --background-color: ${(props) => props.theme.color.manRed700};
  }
`

const TertiaryButton = styled(BaseButton)`
  --background-color: ${(props) => props.theme.color.manAnthracite};
  --outline-color: rgba(31, 41, 51, 0.2);

  &:focus,
  &:hover {
    --background-color: ${(props) => props.theme.color.manAnthracite700};
  }
`

const IconWrapper = styled.div`
  padding-right: ${(props) =>
    !props.$iconOnly && props.iconPosition === 'start' ? '1em' : null};
  padding-left: ${(props) =>
    !props.$iconOnly && props.iconPosition === 'end' ? '1em' : null};

  ${Icon} {
    font-size: 1.2em;
  }
`

const componentByType = {
  primary: PrimaryButton,
  tertiary: TertiaryButton,
}

export const ManButton = ({
  children: childrenProp,
  styleType,
  iconPosition,
  iconName,
  iconRotation,
  disabled,
  width,
  onClick,
  onDisabledClick,
  iconOnly,
  size,
  ...props
}) => {
  const icon =
    typeof iconName !== 'undefined' ? (
      <IconWrapper iconPosition={iconPosition} $iconOnly={iconOnly}>
        <ManIcon iconName={iconName} iconRotation={iconRotation} />
      </IconWrapper>
    ) : null

  const children = iconOnly ? null : childrenProp

  const childrenWithIcon =
    iconPosition === 'start' ? (
      <>
        {icon}
        {children}
      </>
    ) : (
      <>
        {children}
        {icon}
      </>
    )

  const ButtonComponent = componentByType[styleType]

  return (
    <ButtonComponent
      $disabled={disabled}
      $width={width}
      $size={size}
      onClick={disabled ? onDisabledClick : onClick}
      {...props}
    >
      {childrenWithIcon}
    </ButtonComponent>
  )
}

ManButton.propTypes = {
  styleType: propTypes.oneOf(['primary', 'tertiary']).isRequired,
  width: propTypes.oneOf(['auto', 'full']).isRequired,
  icon: propTypes.string,
  iconPosition: propTypes.oneOf(['start', 'end']).isRequired,
  iconRotation: propTypes.number,
  onClick: propTypes.func,
  onDisabledClick: propTypes.func,
  size: propTypes.oneOf(['small', 'medium', 'large', 'special::stickyButton'])
    .isRequired,
  iconOnly: propTypes.bool.isRequired,
}

ManButton.defaultProps = {
  width: 'full',
  iconPosition: 'start',
  size: 'medium',
  styleType: 'primary',
  iconOnly: false,
}
