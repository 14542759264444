import styled from 'styled-components'

export const LoadingSpinner = styled.div`
  margin: 60px auto;
  position: relative;
  font-size: 9px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0);
  border-right: 1.1em solid rgba(255, 255, 255, 0);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: loadingSpinnerRotation 1.1s infinite linear;

  &,
  &::after {
    border-radius: 50%;
    width: 64px;
    height: 64px;
  }

  @keyframes loadingSpinnerRotation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const LoadingSpinnerOverlay = styled.div`
  position: ${(props) => props.position || 'absolute'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${(props) => props.theme.color.anthracite800};
    opacity: 0.5;
  }
`
