import React, { useEffect, useRef, useState } from 'react'
import { down, up } from 'styled-breakpoints'
import styled from 'styled-components'
import { H3, Headline } from './Headline'
import { headerHeight } from './ManHeader'

const StageSpacer = styled.div`
  width: 100%;
  min-height: 500px;
  height: calc(100vh - ${headerHeight.mobile});
  ${up('lg')} {
    height: calc(100vh - ${headerHeight.desktop});
  }
`

const Stage = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 1500px;
  z-index: 0;
  #stage canvas {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

  ${down('xs')} {
    height: ${(props) => props.height}px;
  }
`

const StageBackground = styled.div`
  height: 100%;
  position: relative;
  z-index: -1;
`

const VideoContainer = styled.section`
  position: relative;
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  background: #ccc;
  aspect-ratio: 1080/1920;
`

const VideoFullScreen = styled.video`
  position: absolute;
  z-index: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 1136px) {s
    display: none;
  }

  &::-webkit-media-controls {
    display: none !important;
  }
`

const VideoTabletScreen = styled.video`
  position: absolute;
  z-index: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (min-width: 1135px) {
    display: none;
  }

  &::-webkit-media-controls {
    display: none !important;
  }
`

const VideoMobilelScreen = styled.video`
  position: absolute;
  z-index: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (min-width: 640px) {
    display: none;
  }

  &::-webkit-media-controls {
    display: none !important;
  }
`

const VideoContent = styled.div`
  z-index: 1;
  display: flex;
`

const StageContent = styled.div`
  position: absolute;
  color: white;
  z-index: 300;
  left: 0;
  bottom: 0;
  width: 100%;

  & > * {
    margin-bottom: 1rem;
  }
`

const Subline = styled(H3)`
  text-transform: none;
`

const VideoOverlay = styled.div`
  position: absolute;
  top: 40%;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 1;
  opacity: 1;
`

export const ManStage = ({
  headline,
  ctaScrollToRef,
  videoLink,
  videoLinkWebm,
  mobileVideoLink,
  mobileVideoWebm,
  tabletVideo,
  tabletVideoWebm,
}) => {
  const stageRef = useRef()

  const [screenHeight, setScreenHeight] = useState(0)
  const [viewportHeight, setViewportHeight] = useState(0)

  useEffect(() => {
    if (!stageRef.current) return

    const onScrollY = (scrollY) => {
      if (stageRef.current) {
        window.requestAnimationFrame(() => {
          stageRef.current.style = `transform: translateY(-${scrollY * 0.5}px)`
        })
      }
    }

    window.addEventListener('scroll', () => onScrollY(window.scrollY))

    setScreenHeight(window.screen.height)
    setViewportHeight(window.innerHeight)

    return () => {
      window.removeEventListener('scroll', onScrollY)
    }
  }, [])

  const ctaClicked = (evt) => {
    if (ctaScrollToRef.current) {
      ctaScrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <StageSpacer>
      <Stage ref={stageRef} height={screenHeight}>
        <StageBackground>
          <VideoOverlay />
          <VideoContainer>
            <VideoFullScreen playsinline autoPlay muted loop>
              <source src={videoLinkWebm} type='video/webm' />
              <source src={videoLink} type='video/mp4' />
            </VideoFullScreen>

            <VideoTabletScreen playsinline autoPlay muted loop>
              <source src={tabletVideoWebm} type='video/webm' />
              <source src={tabletVideo} type='video/mp4' />
            </VideoTabletScreen>

            <VideoMobilelScreen playsinline autoPlay muted loop>
              <source src={mobileVideoWebm} type='video/webm' />
              <source src={mobileVideoLink} type='video/mp4' />
            </VideoMobilelScreen>

            <VideoContent>
              <StageContent
                height={screenHeight}
                viewportHeight={viewportHeight}
              >
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-xxl-11'>
                      <Headline size='xl' as='h1'>
                        {headline}
                      </Headline>
                    </div>
                  </div>
                </div>
              </StageContent>
            </VideoContent>
          </VideoContainer>
        </StageBackground>
      </Stage>
    </StageSpacer>
  )
}
