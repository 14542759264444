import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { colorBySeverity } from './InputField'
import CloseIcon from '../../assets/custom-icons/close_cirlce.svg'
import CheckCircle from '../../assets/custom-icons/check_circle.svg'
import WarningCircle from '../../assets/custom-icons/warning_circle.svg'

const iconBySeverity = {
  success: CheckCircle,
  error: CloseIcon,
  warning: WarningCircle,
}

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.color[colorBySeverity[props.$severity]]};
  font-size: 15px;
  display: flex;
  margin-top: 0.4em;
`

const ErrorMessageText = styled.span`
  color: inherit;
`

const IconWrapper = styled.span`
  display: inline-block;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  color: white;
  margin-right: 0.5em;
  position: relative;
  top: 0.1em;
  border-radius: 100%;

  svg {
    fill: ${(props) => props.theme.color[colorBySeverity[props.$severity]]};
  }
`

export const FieldMessage = ({ severity, message }) => {
  const Icon = iconBySeverity[severity]

  return (
    <ErrorMessage $severity={severity}>
      {Icon && (
        <IconWrapper $severity={severity}>
          <Icon />
        </IconWrapper>
      )}

      <ErrorMessageText>{message}</ErrorMessageText>
    </ErrorMessage>
  )
}

FieldMessage.propTypes = {
  severity: propTypes.oneOf(['success', 'warning', 'error', 'help']).isRequired,
  message: propTypes.string,
}

FieldMessage.defaultProps = {
  severity: 'error',
}
