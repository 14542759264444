export const getReCaptchaToken = () => {
  return new Promise((resolve) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.GATSBY_RECAPTCHA_KEY, { action: 'submit' })
        .then((token) => {
          resolve(token)
        })
    })
  })
}
