import React, { useState } from 'react'
import { useField } from 'formik'
import { FieldMessage } from '../FieldMessage'
import { MultipleInput } from '../MultipleInput'
import { useTranslation } from 'react-i18next'

export const FormikMultipleInput = ({
  helperText,
  label,
  options,
  ...props
}) => {
  const { t } = useTranslation()
  const [customError, setCustomError] = useState(null)
  const [field, meta, helper] = useField(props.field.name)

  return (
    <>
      <MultipleInput
        {...props}
        label={label}
        name={field.name}
        onChange={(value) => {
          helper.setValue(value)
        }}
        value={field.value}
        severity={(meta.touched && meta.error) || customError ? 'error' : null}
        onBlur={() => helper.setTouched(true)}
        setError={setCustomError}
      />
      {customError && <FieldMessage message={t(customError)} />}
      {meta.touched && meta.error && <FieldMessage message={t(meta.error)} />}
      {helperText && <FieldMessage message={helperText} severity='help' />}
    </>
  )
}
